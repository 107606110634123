.App {
  text-align: center;
  margin:0;
}

.App-header {
  background-color: #222325;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.Nav-Bar
{
  position: fixed;
  z-index: 1;
}

