.navbar {
  /*background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */

  
  height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
}

.navbar-container {
  display: flex;
  justify-content: center;
  height: 80px;
}

.navbar-logo {
  height: 80%;
  margin: 10px ;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

