
.herobackgroundContainter {
  height: 80vh;
  width: 100%;
  position: fixed;
  z-index: -1;
  object-fit: cover;
  display: flex;
  flex-direction: column;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-color: #5f5f5f;
}

.logoContainer
{
  margin: 5%;
}

.hero-container  h1 {
  color: #fff;
  font-size: 60px;
  font-stretch: expanded;
}

.hero-container  p {
  margin-top: 10px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}


.hero-logo
{
  height: 120px;
}


@media screen and (max-width: 960px) {
  .hero-container h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container h1 {
    font-size: 40px;
  }

  .hero-container p {
    font-size: 20px;
  }

  .hero-logo
  {
    height: 80px;
  }
}
